import * as React from 'react'
import Layout from '../../components/layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as style from './blog.module.css'



const BlogPost = ({ data }) => {
  // const image = getImage(data.mdx.frontmatter.hero_image)

  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <div class="text-textSecondary text-lg">
        {data.mdx.frontmatter.date}
      </div>
      <div class="text-textSecondary text-lg mb-8">
        {data.mdx.timeToRead} {data.mdx.timeToRead == 1 ? 'minute' : 'minutes'} to read
      </div>
      {/* <div>
          Tags: {data.mdx.frontmatter.tags.map((tag, i, tagList) => (<Link to={`/tags/${tag.replace(" ", "-").toLowerCase()}`}>{tag}{tagList.length == i+1 ? '' : ', '}</Link> ))}
      </div> */}
      {/* { image != null &&
      <GatsbyImage
      image={image}
      alt={data.mdx.frontmatter.hero_image_alt}
      />} */}

      {/* <p>
        Photo Credit:{" "}
        <a href={data.mdx.frontmatter.hero_image_credit_link}>
          {data.mdx.frontmatter.hero_image_credit_text}
        </a> 
      </p> */}
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    mdx(id: {eq: $id}) {
      body
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }
  }
`

export default BlogPost